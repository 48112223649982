exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activity-tsx": () => import("./../../../src/pages/activity.tsx" /* webpackChunkName: "component---src-pages-activity-tsx" */),
  "component---src-pages-announcement-tsx": () => import("./../../../src/pages/announcement.tsx" /* webpackChunkName: "component---src-pages-announcement-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-ecosystem-tsx": () => import("./../../../src/pages/ecosystem.tsx" /* webpackChunkName: "component---src-pages-ecosystem-tsx" */),
  "component---src-pages-faucet-tsx": () => import("./../../../src/pages/faucet.tsx" /* webpackChunkName: "component---src-pages-faucet-tsx" */),
  "component---src-pages-getnew-tsx": () => import("./../../../src/pages/getnew.tsx" /* webpackChunkName: "component---src-pages-getnew-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-voice-tsx": () => import("./../../../src/pages/voice.tsx" /* webpackChunkName: "component---src-pages-voice-tsx" */),
  "component---src-templates-communities-tsx": () => import("./../../../src/templates/communities.tsx" /* webpackChunkName: "component---src-templates-communities-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../../../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-learn-docs-tsx": () => import("./../../../src/templates/learnDocs.tsx" /* webpackChunkName: "component---src-templates-learn-docs-tsx" */)
}

